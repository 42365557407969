.title {
  margin: 20px 0px;
  font-size: 24px;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 50px;
  padding-top: 20px;
  width: 100%;
  min-height: 60vh;
}

.content-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  min-width: 500px;
}

.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}

.tabela-item {
  cursor: pointer;
  text-align: center;
}

.text-tabela {
  margin-bottom: 0;
  text-align: center;
}
