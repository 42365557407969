.container-perfil-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-header-list {
  display: flex;
  justify-content: space-between;
  padding-right: 60px;
}

.pc-container-buttons {
  display: flex;
  gap: 10px;
}

.mobile-container-buttons {
  display: none;
}

.button-novo-perfil {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  width: 80px;
}

.titulo-modal-desativar-perfil {
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .pc-container-buttons {
    display: none;
  }

  .mobile-container-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .container-header-list {
    padding-right: 4px;
  }
}
