.titulo-modal {
  font-size: 1.05rem;
  text-align: center;
}

.subtitulo-modal {
  opacity: 0.7;
  margin-bottom: 0;
  font-size: 0.875rem;
  text-align: center;
}
