.title-module {
  margin: 20px 0px;
  font-size: 24px;
  text-align: center;
}

.container-balanca {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.container-dados-amostra {
  display: flex;
  gap: 50px;
  margin-top: 20px;
}

.container-button-add {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
