.site-layout .site-layout-background {
  background: #fff;
}

.site-layout .content {
  margin: 24px 16px;
  padding: 24px 48px;
  min-height: 280px;
}

@media screen and (max-width: 700px) {
  .site-layout {
    transition: margin-left 0.2s;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 500px) {
  .site-layout .content {
    margin: 20px 12px;
    padding: 12px 24px;
  }
}
