.title-module {
  margin: 20px 0px;
  font-size: 24px;
  text-align: center;
}

.container-balanca {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.container-button {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 20px 0px;
  width: 100%;
  overflow-x: auto;
}

.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}
