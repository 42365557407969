.title-modal {
  margin-bottom: 0px;
  padding: 0px 15px;
  font-size: 18px;
  text-align: center;
}

.select-destino {
  width: 90%;
}

.label-destino {
  display: block;
  padding-bottom: 5px;
}

.container {
  display: flex;
  flex-direction: column;
}
