.width-tif {
  width: 48.75rem;
}

.titulo-questionario {
  display: block;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  background-color: #d9d9d9;
  font-weight: bold;
  text-align: center;
}

.titulo-atividade {
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  -moz-text-decoration-line: underline;
}

.container-atividades {
  display: block;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.resposta-pergunta {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.titulo-pergunta {
  margin-bottom: 0px;
  font-size: 12px;
}

.container-questionario-impressao {
  page-break-before: always;
  width: 780px;
}
