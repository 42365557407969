.label-campo-vistoria::before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  font-family: SimSun, sans-serif;
}

.container-title-question {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.container-list-question {
  display: flex;
  flex-direction: column;
}
