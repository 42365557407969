.header-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0px 20px 0px 20px;
}

.menu-mobile {
  display: none;
}

.menu-pc {
  display: initial;
}

.trigger {
  transition: color 0.3s;
  cursor: pointer;
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
}

.title-homolog {
  width: 100vw;
  color: red;
  font-weight: bold;
  font-size: 21px;
  text-align: center;
}

.container-notification-button-leave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 700px) {
  .menu-mobile {
    display: inline;
  }

  .menu-pc {
    display: none;
  }

  .title-homolog {
    font-size: 16px;
  }

  .header-form {
    padding: 0px 20px;
  }
}
