.campo {
  border-bottom: 1px solid black;
  padding-left: 5px;
  min-height: 2vh;
}

.container-line-more-items {
  display: flex;
  border-bottom: 1px solid black;
  min-height: 2vh;
}

.title-topico {
  border-bottom: 1px solid black;
  background-color: #d9d9d9;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
