.mobile-menu-lista-termos {
  display: none;
}

.pc-menu-lista-termos {
  display: flex;
  gap: 10px;
}

.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}

.container-filtro-selected {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.heigth-list {
  min-height: 39vh;
}

.container-button-listagem {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .pc-menu-lista-termos {
    display: none;
  }

  .mobile-menu-lista-termos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
}
