.container-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.content-items {
  display: flex;
  justify-content: space-between;
}
