.nome-menu {
  font-size: 18px;
  text-align: center;
}

.container-permissoes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  min-height: 70vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkbox {
  display: flex;
  column-gap: 75px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.options {
  font-size: 16px !important;
}

@media screen and (max-width: 500px) {
  .checkbox {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
