.container-button {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.container-confirm {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.title {
  margin-bottom: 0px;
  padding: 0px 15px;
  font-size: 18px;
  text-align: center;
}

.sub-title {
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  text-align: center;
}
