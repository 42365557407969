.logo {
  margin-top: 16px;
  margin-bottom: 16px;
  width: auto;
  height: 64px;
  user-select: none;
}

.sideBar {
  display: initial;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  overflow: auto;
}

.content-name-user {
  opacity: 0.7;
  cursor: default;
  padding-bottom: 10px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
}

@media screen and (max-width: 700px) {
  .sideBar {
    display: none;
  }
}
