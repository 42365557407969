.button-nome-fiscalizado {
  cursor: pointer;
  /* Resets */
  border: none;
  background-color: transparent;
}

.button-nome-fiscalizado :hover {
  background-color: aquamarine;
  color: blue;
}
