.width-tif {
  width: 48.75rem;
}

.container-tif-impressao {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .container-tif-impressao {
    display: none;
  }
}
