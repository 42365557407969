* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #ffff;
  width: 100%;
  height: 100%;
}

.title-description-cancelamento {
  display: block;
  width: 100%;
  text-align: center;
}

.servidor-signature-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  width: 600px;
}

.fiscalizado-signature-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.container-img-assinatura {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid black;
  max-width: 600px;
  height: 200px;
}

.sigCanvas {
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 500px;
}

.titulo-card {
  color: rgb(13, 13, 13);
  font-size: 1rem;
}

.subtitulo-card {
  opacity: 0.9;
  font-size: 0.875rem;
}

.lista-item {
  gap: 10px;
}

.tif-impressao-font {
  font-family: "Roboto Mono", monospace;
}

.termo-coleta-impressao-font {
  font-family: "Roboto Mono", monospace;
}

.termo-apreensao-liberacao-impressao-font {
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
}

.termo-vistoria-font {
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
}

.tid-impressao-font {
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
}

.marcadagua {
  display: none;
}

@media screen and (max-width: 500px) {
  .titulo-card {
    font-size: 0.875rem;
  }

  .subtitulo-card {
    font-size: 0.75rem;
  }
}

@media print {
  .marcadagua {
    display: inline;
    position: fixed;
    top: 40%;
    transform: rotate(-45deg);
    opacity: 0.3;
    z-index: 0;
    width: 100%;
    font-size: 125px;
    text-align: center;
  }
}

@page {
  margin: 4mm !important;
}
