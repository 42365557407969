.select-destino {
  width: 90%;
  max-width: 500px;
}

.label-destino {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
