.button-anexos {
  transition: color 0.3s;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #1890ff;
  text-decoration: none;
}

.button-anexos:hover {
  color: #40a9ff;
}
