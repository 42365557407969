.container-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.container-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
