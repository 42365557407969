.titulo-modal {
  margin-bottom: 0px;
  padding: 0px 25px;
  font-size: 1.05rem;
  text-align: center;
}

.container-perguntas {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 60vh;
  overflow: auto;
}

.container-dados-assinante {
  display: flex;
  gap: 10px;
  max-width: 600px;
}

.img-assinatura {
  width: 90%;
  height: 90%;
}

.titulo-pergunta {
  margin-bottom: 2px;
  font-size: 1rem;
}

.footer-modal {
  display: flex;
  justify-content: space-between;
}

.label-alternativa label {
  padding-right: 10px;
}

.button-anexos {
  transition: color 0.3s;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #1890ff;
  text-decoration: none;
}

.button-anexos:hover {
  color: #40a9ff;
}

@media screen and (max-width: 500px) {
  .titulo-pergunta {
    font-size: 0.875rem;
  }

  .titulo-modal {
    font-size: 0.95rem;
  }
}
