.title-topico {
  font-size: 20px;
}

.content-dados-amostra {
  display: flex;
  column-gap: 100px;
  flex-wrap: wrap;
  font-size: 16px;
}

.container {
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
}
