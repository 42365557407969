.titulo-modal {
  margin-bottom: 0px;
  padding: 0px 25px;
  font-size: 1.05rem;
  text-align: center;
}

.container-perguntas {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 60vh;
  overflow: auto;
}

.titulo-pergunta {
  margin-bottom: 2px;
  font-size: 1rem;
}

.footer-modal {
  display: flex;
  justify-content: space-between;
}

.label-alternativa label {
  padding-right: 10px;
}

.label-campo::before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  font-family: SimSun, sans-serif;
}

@media screen and (max-width: 500px) {
  .titulo-pergunta {
    font-size: 0.875rem;
  }

  .titulo-modal {
    font-size: 0.95rem;
  }
}
