.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.text-label {
  opacity: 0.7;
  color: black;
}
