.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}

.container-filtro {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}
