.editable-cell-value-wrap {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 4px;
  height: 32px;
  text-align: center;
}

.editable-cell-value-wrap[contentEditable="true"]:empty:before {
  opacity: 0.5;
  content: attr(placeholder);
}
