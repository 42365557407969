.title {
  font-size: 18px;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
